
import { SettingsAction } from '@/store/actions.type'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import { ICountryStateResponse } from '@/types/interfaces/ICountryStateResponse'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { useStore } from 'vuex'

const itemWrapperCol = { sm: { span: 14 }, xs: { span: 24 } }
const itemLabelCol = { sm: { span: 6 }, xs: { span: 24 } }

const defaultAddress: IAddressFormat = {
  country: 'India',
  attention: '',
  city: '',
  state: '',
  address: '',
  pincode: '',
}

export default defineComponent({
  props: {
    address: {
      type: Object as PropType<IAddressFormat>,
      default: (): IAddressFormat => {
        return defaultAddress
      },
    },
  },
  emits: ['update:address'],
  setup(props, { emit }) {
    const store = useStore()
    const citySearchText = ref<string | null>(null)
    const selectedStateCode = ref<string | null>(null)
    const countryStateMap = new Map<string, ICountryStateResponse[]>()
    const stateCityMap = new Map<string, ICountryStateResponse[]>()
    const fetchedStates = ref<ICountryStateResponse[] | null>(
      store.state.settings.indianStates as ICountryStateResponse[],
    )
    const fetchedCities = ref<ICountryStateResponse[] | null>(
      store.state.settings.indianCities as ICountryStateResponse[],
    )
    const appCountryOptions = computed(() => {
      const countries = store.state.settings.countries as ICountryStateResponse[]
      return countries?.map((x) => {
        return {
          key: x.countryCode,
          value: x.name,
          label: x.name,
        }
      })
    })
    const statesOptions = computed(() =>
      fetchedStates?.value?.map((x) => {
        return {
          key: x.stateCode,
          value: x.name,
          label: x.name,
        }
      }),
    )
    const cityOptions = computed(() =>
      fetchedCities?.value
        ?.filter((x) => x.stateCode === selectedStateCode.value)
        .filter((x) =>
          citySearchText.value
            ? x.name.toUpperCase().includes(citySearchText.value?.toUpperCase())
            : x,
        )
        ?.map((x) => {
          return {
            value: x.name,
            key: x.name,
            text: x.name,
          }
        }),
    )

    const localAddress = computed({
      get() {
        return props.address
      },

      set() {
        emit('update:address', localAddress)
      },
    })

    const fetchStates = async (countryCode: string) => {
      if (!countryCode) {
        return null
      }
      if (countryStateMap.has(countryCode)) {
        return countryStateMap.get(countryCode) || null
      }
      const statesData: ICountryStateResponse[] | null = await store.dispatch(
        `${SettingsAction.FETCH_STATES_BY_COUNTRY}`,
        { countryCode: countryCode },
        {
          root: true,
        },
      )
      if (statesData) {
        countryStateMap.set(countryCode, statesData)
        return statesData
      } else {
        return null
      }
    }

    const fetchCities = async (countryCode: string) => {
      if (!countryCode) {
        return null
      }
      if (stateCityMap.has(countryCode)) {
        return stateCityMap.get(countryCode) || null
      }
      const citiesData: ICountryStateResponse[] | null = await store.dispatch(
        `${SettingsAction.FETCH_CITIES_BY_COUNTRY}`,
        { countryCode: countryCode },
        {
          root: true,
        },
      )
      if (citiesData) {
        stateCityMap.set(countryCode, citiesData)
        return citiesData
      } else {
        return null
      }
    }

    const handleCountryChange = async (_: any, opt: any) => {
      // Check if city should also be reset.
      localAddress.value.state = null
      fetchedStates.value = await fetchStates(opt?.key)
      fetchedCities.value = await fetchCities(opt?.key)
    }

    const handleStateChange = (_: any, opt: any) => {
      selectedStateCode.value = opt?.key
    }

    const handleCitySearch = (input: string) => {
      citySearchText.value = input
    }

    onMounted(() => {
      if (fetchedStates.value) {
        countryStateMap.set('IN', fetchedStates.value)
      }
      if (fetchedCities.value) {
        stateCityMap.set('IN', fetchedCities.value)
      }
    })

    return {
      localAddress,
      appCountryOptions,
      handleCountryChange,
      statesOptions,
      cityOptions,
      handleStateChange,
      handleCitySearch,
      itemWrapperCol,
      itemLabelCol,
    }
  },
})
