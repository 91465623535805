
import { ISocialData } from '@/types/interfaces/ISocialData'
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
  props: {
    social: {
      type: Object as PropType<ISocialData>,
      required: true,
      default: (): ISocialData => {
        return {
          facebook: null,
          linkedin: null,
          twitter: null,
        }
      },
    },
  },
  emits: ['update:social'],
  setup(props, { emit }) {
    const localSocial = computed({
      get() {
        return props.social
      },

      set() {
        emit('update:social', localSocial)
      },
    })

    return {
      localSocial,
    }
  },
})
