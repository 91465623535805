<template>
  <div>
    <a-row>
      <a-col :md="12">
        <a-form-item
          label="Attention"
          name="attention"
          :wrapper-col="itemWrapperCol"
          :label-col="itemLabelCol"
        >
          <a-input
            placeholder="Enter Attention (Ex. Home, Office etc)"
            v-model:value="localAddress.attention"
            allow-clear
          />
        </a-form-item>
      </a-col>
      <a-col :md="12">
        <a-form-item
          :wrapper-col="itemWrapperCol"
          :label-col="itemLabelCol"
          label="Country"
          name="country"
        >
          <a-select
            placeholder="Select Country"
            v-model:value="localAddress.country"
            :show-search="true"
            option-filter-prop="label"
            :options="appCountryOptions"
            @change="handleCountryChange"
            style="width: 200px;"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :md="12">
        <a-form-item
          :wrapper-col="itemWrapperCol"
          :label-col="itemLabelCol"
          label="State"
          name="state"
        >
          <a-select
            placeholder="Select State"
            v-model:value="localAddress.state"
            :show-search="true"
            option-filter-prop="label"
            :options="statesOptions"
            @change="handleStateChange"
            style="width: 200px;"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12">
        <a-form-item
          :wrapper-col="itemWrapperCol"
          :label-col="itemLabelCol"
          label="City"
          name="city"
        >
          <a-auto-complete
            v-model:value="localAddress.city"
            :options="cityOptions"
            @search="handleCitySearch"
            :backfill="true"
            style="width: 100%;"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-row>
      <a-col :md="12">
        <a-form-item
          :wrapper-col="itemWrapperCol"
          :label-col="itemLabelCol"
          label="Address"
          name="address"
          style="width: '100%';"
        >
          <a-textarea
            placeholder="Enter Street Address"
            v-model:value="localAddress.address"
            :rows="4"
            allow-clear
          />
        </a-form-item>
      </a-col>
      <a-col :md="12">
        <a-form-item
          :wrapper-col="itemWrapperCol"
          :label-col="itemLabelCol"
          label="Pincode"
          name="pincode"
        >
          <a-input placeholder="Enter Pincode" v-model:value="localAddress.pincode" allow-clear />
        </a-form-item>
      </a-col>
    </a-row>
  </div>
</template>

<script lang="ts">
import { SettingsAction } from '@/store/actions.type'
import { IAddressFormat } from '@/types/interfaces/IAddressFormat'
import { ICountryStateResponse } from '@/types/interfaces/ICountryStateResponse'
import { computed, defineComponent, onMounted, PropType, ref } from 'vue'
import { useStore } from 'vuex'

const itemWrapperCol = { sm: { span: 14 }, xs: { span: 24 } }
const itemLabelCol = { sm: { span: 6 }, xs: { span: 24 } }

const defaultAddress: IAddressFormat = {
  country: 'India',
  attention: '',
  city: '',
  state: '',
  address: '',
  pincode: '',
}

export default defineComponent({
  props: {
    address: {
      type: Object as PropType<IAddressFormat>,
      default: (): IAddressFormat => {
        return defaultAddress
      },
    },
  },
  emits: ['update:address'],
  setup(props, { emit }) {
    const store = useStore()
    const citySearchText = ref<string | null>(null)
    const selectedStateCode = ref<string | null>(null)
    const countryStateMap = new Map<string, ICountryStateResponse[]>()
    const stateCityMap = new Map<string, ICountryStateResponse[]>()
    const fetchedStates = ref<ICountryStateResponse[] | null>(
      store.state.settings.indianStates as ICountryStateResponse[],
    )
    const fetchedCities = ref<ICountryStateResponse[] | null>(
      store.state.settings.indianCities as ICountryStateResponse[],
    )
    const appCountryOptions = computed(() => {
      const countries = store.state.settings.countries as ICountryStateResponse[]
      return countries?.map((x) => {
        return {
          key: x.countryCode,
          value: x.name,
          label: x.name,
        }
      })
    })
    const statesOptions = computed(() =>
      fetchedStates?.value?.map((x) => {
        return {
          key: x.stateCode,
          value: x.name,
          label: x.name,
        }
      }),
    )
    const cityOptions = computed(() =>
      fetchedCities?.value
        ?.filter((x) => x.stateCode === selectedStateCode.value)
        .filter((x) =>
          citySearchText.value
            ? x.name.toUpperCase().includes(citySearchText.value?.toUpperCase())
            : x,
        )
        ?.map((x) => {
          return {
            value: x.name,
            key: x.name,
            text: x.name,
          }
        }),
    )

    const localAddress = computed({
      get() {
        return props.address
      },

      set() {
        emit('update:address', localAddress)
      },
    })

    const fetchStates = async (countryCode: string) => {
      if (!countryCode) {
        return null
      }
      if (countryStateMap.has(countryCode)) {
        return countryStateMap.get(countryCode) || null
      }
      const statesData: ICountryStateResponse[] | null = await store.dispatch(
        `${SettingsAction.FETCH_STATES_BY_COUNTRY}`,
        { countryCode: countryCode },
        {
          root: true,
        },
      )
      if (statesData) {
        countryStateMap.set(countryCode, statesData)
        return statesData
      } else {
        return null
      }
    }

    const fetchCities = async (countryCode: string) => {
      if (!countryCode) {
        return null
      }
      if (stateCityMap.has(countryCode)) {
        return stateCityMap.get(countryCode) || null
      }
      const citiesData: ICountryStateResponse[] | null = await store.dispatch(
        `${SettingsAction.FETCH_CITIES_BY_COUNTRY}`,
        { countryCode: countryCode },
        {
          root: true,
        },
      )
      if (citiesData) {
        stateCityMap.set(countryCode, citiesData)
        return citiesData
      } else {
        return null
      }
    }

    const handleCountryChange = async (_: any, opt: any) => {
      // Check if city should also be reset.
      localAddress.value.state = null
      fetchedStates.value = await fetchStates(opt?.key)
      fetchedCities.value = await fetchCities(opt?.key)
    }

    const handleStateChange = (_: any, opt: any) => {
      selectedStateCode.value = opt?.key
    }

    const handleCitySearch = (input: string) => {
      citySearchText.value = input
    }

    onMounted(() => {
      if (fetchedStates.value) {
        countryStateMap.set('IN', fetchedStates.value)
      }
      if (fetchedCities.value) {
        stateCityMap.set('IN', fetchedCities.value)
      }
    })

    return {
      localAddress,
      appCountryOptions,
      handleCountryChange,
      statesOptions,
      cityOptions,
      handleStateChange,
      handleCitySearch,
      itemWrapperCol,
      itemLabelCol,
    }
  },
})
</script>
