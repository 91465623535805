<template>
  <div>
    <a-form-item label="Linked In">
      <a-input placeholder="Enter URL" v-model:value="localSocial.linkedin">
        <template #addonBefore>
          <i class="fa fa-linkedin"></i>
        </template>
      </a-input>
    </a-form-item>
    <a-form-item label="Facebook">
      <a-input placeholder="Enter URL" v-model:value="localSocial.facebook">
        <template #addonBefore>
          <i class="fa fa-facebook-official"></i>
        </template>
      </a-input>
    </a-form-item>
    <a-form-item label="Twitter">
      <a-input placeholder="Enter URL" v-model:value="localSocial.twitter">
        <template #addonBefore>
          <i class="fa fa-twitter"></i>
        </template>
      </a-input>
    </a-form-item>
  </div>
</template>

<script lang="ts">
import { ISocialData } from '@/types/interfaces/ISocialData'
import { defineComponent, computed, PropType } from 'vue'

export default defineComponent({
  props: {
    social: {
      type: Object as PropType<ISocialData>,
      required: true,
      default: (): ISocialData => {
        return {
          facebook: null,
          linkedin: null,
          twitter: null,
        }
      },
    },
  },
  emits: ['update:social'],
  setup(props, { emit }) {
    const localSocial = computed({
      get() {
        return props.social
      },

      set() {
        emit('update:social', localSocial)
      },
    })

    return {
      localSocial,
    }
  },
})
</script>
