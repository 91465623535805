
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router'
import { computed, defineComponent, onBeforeMount, reactive, ref, toRaw } from 'vue'
import { useStore } from 'vuex'
import * as actions from '../../../../store/actions.type'
import InputFormSocial from '@/components/InputForms/InputFormSocial.vue'
import InputFormAddress from '@/components/InputForms/InputFormAddress.vue'
import InputFormContactPersonModal from '@/components/InputForms/InputFormContactPersonModal.vue'
import { IContactPerson } from '@/types/interfaces/IContactPerson'
import { CustomerFormData } from '@/types/appContracts/CustomerFormData'
import { CustomerTypes } from '@/types/enums/CustomerTypes'
import { CustomersDocument } from '@/types/firebaseCollectionContracts/CustomersDocument'
import { CustomerBooksDocument } from '@/types/firebaseCollectionContracts/CustomerBooksDocument'
import { message } from 'ant-design-vue'
import { DocumentOperation } from '@/types/appcontracts/DocumentOperation'
import { DocumentActions } from '@/types/enums/DocumentActions'
import { IDocumentAudit } from '@/types/interfaces/IDocumentAudit'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}

const contactPersonColumns = [
  {
    title: 'Full Name',
    dataIndex: 'cpName',
    key: 'cpName',
  },
  {
    title: 'Email',
    dataIndex: 'cpEmail',
    ellipsis: true,
    key: 'cpEmail',
  },
  {
    title: 'Designation',
    dataIndex: 'cpDesignation',
    key: 'cpDesignation',
  },
  {
    title: 'Mobile',
    dataIndex: 'cpMobile',
    key: 'cpMobile',
  },
  {
    title: 'Notes',
    dataIndex: 'cpNotes',
    key: 'cpNotes',
    ellipsis: true,
  },
  {
    title: 'Actions',
    key: 'actions',
    slots: { customRender: 'actions' },
  },
]

const defaultFormData: CustomerFormData = {
  cid: null,
  customerType: CustomerTypes.Individual,
  fullName: null,
  companyName: null,
  displayName: null,
  email: null,
  mobile: null,
  pan: null,
  gstin: null,
  primaryAddress: {
    country: 'India',
    attention: null,
    city: null,
    state: null,
    address: null,
    pincode: null,
  },
  openingBalance: 0,
  website: null,
  contactPersons: [],
  social: { linkedin: null, facebook: null, twitter: null },
  birthday: null,
  photoUrl: null,
}

export default defineComponent({
  components: {
    InputFormSocial,
    InputFormAddress,
    InputFormContactPersonModal,
  },

  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const loading = ref(false)
    const isProfileLoading = ref(false)
    const docId = computed(() => route.params.id)
    const prevAudit = ref<IDocumentAudit | null>(null)
    const isAddContactPersonModalVisible = ref(false)
    const formState = reactive({ ...defaultFormData })

    const rules = {
      displayName: [
        { required: true, message: 'Please enter customer display name', trigger: 'blur' },
      ],
      email: [{ type: 'email', message: 'Please input a valid email address', trigger: 'blur' }],
      gstin: [{ type: 'string', len: 15, message: 'Please enter valid gstin', trigger: 'blur' }],
    }

    const displayNameOptions = computed(() => {
      let displayNameArr: Array<Object> = []

      if (formState.fullName) {
        displayNameArr.push({ value: formState.fullName })
      }

      if (formState.companyName) {
        displayNameArr.push({ value: formState.companyName })
      }

      return displayNameArr
    })

    const handleFinish = async () => {
      loading.value = true
      const docOperation: DocumentOperation<CustomerFormData> = {
        action: DocumentActions.UPDATE,
        id: docId.value as string,
        payload: toRaw(formState),
        audit: prevAudit.value,
      }
      await store.dispatch(
        `customers/${actions.CustomersAction.SET_OR_DELETE_CUSTOMER}`,
        docOperation,
      )
      loading.value = false
      router.push({ name: 'customerProfile', params: { id: docId.value } })
    }

    const handleFinishFailed = async (errors: any) => {
      console.log('ERR: ', errors)
    }

    const handleAddContactPerson = (contactPerson: IContactPerson) => {
      formState.contactPersons.push(contactPerson)
    }

    const fetchProfile = async (cid?: string) => {
      isProfileLoading.value = true
      const resp: {
        customersDoc: CustomersDocument
        customerBooksDoc: CustomerBooksDocument
      } = await store.dispatch(`customers/${actions.CustomersAction.FETCH_CUSTOMER_PROFILE}`, {
        id: cid,
      })
      if (!resp.customerBooksDoc || !resp.customersDoc) {
        message.warn('Customer does not exists.')
        router.push({ name: 'customers' })
      } else {
        let { audit, ...doc } = resp.customersDoc
        Object.assign(formState, doc)
        formState.openingBalance = resp.customerBooksDoc.openingBalance
        prevAudit.value = audit
      }
      isProfileLoading.value = false
    }

    onBeforeMount(async () => {
      await fetchProfile(route.params.id as string)
    })

    onBeforeRouteUpdate(async (to) => {
      await fetchProfile(to.params.id as string)
    })

    return {
      formState,
      rules,
      labelCol,
      wrapperCol,
      loading,
      handleFinish,
      handleFinishFailed,
      isAddContactPersonModalVisible,
      handleAddContactPerson,
      contactPersonColumns,
      displayNameOptions,
      isProfileLoading,
    }
  },
})
