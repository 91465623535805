<template>
  <div>
    <a-modal
      title="Add Contact Person"
      :visible="isModalVisible"
      ok-text="Add"
      @ok="handleContactPersonSubmit"
      @cancel="handleContactPersonCancel"
      :destroy-on-close="true"
    >
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-item label="Name" name="cpName">
          <a-input placeholder="Enter Full Name" v-model:value="formState.cpName" allow-clear />
        </a-form-item>
        <a-form-item label="Email" name="cpEmail">
          <a-input
            placeholder="Enter Email Address"
            v-model:value="formState.cpEmail"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="Designation" name="cpDesignation">
          <a-input
            placeholder="Enter Designation"
            v-model:value="formState.cpDesignation"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="Mobile" name="cpMobile">
          <a-input
            placeholder="Enter Mobile # (10 digit)"
            type="number"
            v-model:value="formState.cpMobile"
            allow-clear
          />
        </a-form-item>
        <a-form-item label="Notes" name="cpNotes">
          <a-textarea placeholder="Enter Notes" v-model:value="formState.cpNotes" allow-clear />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive, ref, toRaw } from 'vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 6 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 16 },
}

export default defineComponent({
  props: {
    isAddContactPersonModalVisible: {
      type: Boolean,
      default: () => {
        return false
      },
    },
  },
  emits: ['update:isAddContactPersonModalVisible', 'onAddContactPerson'],
  setup(props, { emit }) {
    const formRef = ref()
    const formState = reactive({
      cpName: null,
      cpEmail: null,
      cpDesignation: null,
      cpMobile: null,
      cpNotes: null,
    })

    const rules = reactive({
      cpName: [{ required: true, message: 'Please enter customer display name', trigger: 'blur' }],
      cpEmail: [{ type: 'email', message: 'Please input a valid email address', trigger: 'blur' }],
    })

    const isModalVisible = computed({
      get() {
        return props.isAddContactPersonModalVisible
      },
      set() {
        emit('update:isAddContactPersonModalVisible', false)
      },
    })

    const handleContactPersonCancel = () => {
      formRef.value.resetFields()
      isModalVisible.value = false
    }

    const handleContactPersonSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          // NOTE: using just toRaw(obj) creates a reference which changes on
          // resetFields so using it as { ...toRaw(formState) }
          const rawForm = { ...toRaw(formState) }
          emit('onAddContactPerson', rawForm)
          isModalVisible.value = false
          formRef.value.resetFields()
        })
        .catch(() => {
          formRef.value.scrollToField()
        })
    }

    return {
      formState,
      formRef,
      rules,
      labelCol,
      wrapperCol,
      isModalVisible,
      handleContactPersonSubmit,
      handleContactPersonCancel,
    }
  },
})
</script>
